/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import shortid from 'shortid';
import i18n from 'i18next';

// Utils
import { formatDate, formatPhoneNumber } from '@Utils/formatting';

import { translation } from '@Utils/translation';

// Components
import CombinedStatus from '@Assets/config/tables/participants/components/CombinedStatus';
import Attributes from '@Assets/config/tables/participants/components/Attributes';

// Stores
import InvitationStore from '@Stores/InvitationStore';
import { getParticipantManagementPath } from '@Utils/constans/paths';

export const renderRolesNames = key => translation(key, 'rolesNames');

export const renderRolesNamesForACN = roles =>
  roles.map((key, i) => {
    const divider = i === roles.length - 1 ? '' : '; ';
    return (
      <React.Fragment key={shortid.generate()}>
        {`${translation(key, 'rolesNames')}${divider}`}
        <br />
      </React.Fragment>
    );
  });

const renderStatusCell = data => {
  return (
    <CombinedStatus
      status={data.status}
      details={data.details}
      date={formatDate(data.updated)}
    />
  );
};

const renderAttributes = data => {
  return <Attributes attributes={data} />;
};

export const handleResendCode = ({ rowId }) => {
  InvitationStore.resendInvitation(rowId);
  InvitationStore.getParticipants();
};

export const handleSendCode = ({ rowId }) => {
  InvitationStore.sendInvitation(rowId);
  InvitationStore.getParticipants();
};

// TODO: commented code not used in October release
export const tableConfig = {
  defaultOrder: 'desc',
  defaultOrderBy: 'status_updated_at',
  rowIdKey: 'id',
  extraIdKey: 'phone',
  onHeaderCheckboxChange: InvitationStore.toggleAllTableRowChecked,
  onCheckboxChange: InvitationStore.toggleTableRowChecked,
  handleRowClick: (event, id, redirectTo) => {
    event.stopPropagation();
    redirectTo(getParticipantManagementPath.participantView(id));
  },
  headings: [
    'Participant attributes',
    'Role',
    i18n.t('common.phone'),
    'Status'
    // {
    //   id: 'status_updated_at',
    //   title: 'Status update',
    //   sortable: true,
    //   cb: InvitationStore.setOrder
    // }
  ],
  rowCells: [
    { key: 'attributes', cb: renderAttributes },
    { key: 'role.name', cb: renderRolesNames },
    { key: 'phone', cb: formatPhoneNumber },
    { key: 'all', cb: renderStatusCell }
    // { key: 'status_updated_at', cb: formatDate }
  ]
  // actionsConfig: {
  //   filterMenuItems: rowData => {
  //     const sendStatuses = ['PREASSIGNED'];
  //     const resendStatuses = ['INVITATION_PENDING', 'INVITATION_EXPIRED'];
  //     const includedValues = [...sendStatuses, ...resendStatuses];
  //     const { permissions } = AuthStore;
  //
  //     const { status, phone } = rowData;
  //
  //     // Check permissions for resend invite
  //     if (
  //       resendStatuses.includes(status) &&
  //       !permissions.includes(RESEND_INVITATIONS)
  //     ) {
  //       return [];
  //     }
  //
  //     // Check permission for send invite
  //     if (
  //       sendStatuses.includes(status) &&
  //       !permissions.includes(CREATE_INVITATIONS)
  //     ) {
  //       return [];
  //     }
  //
  //     if (includedValues.includes(status)) {
  //       // TODO: Fix it. Use correct 'this' context (this.actionsConfig.menuItems)
  //       return tableConfig.actionsConfig.menuItems.filter(item => {
  //         if (status === item.status) {
  //           // Do not show send icon if there is no phone number
  //           if (item.status === 'PREASSIGNED' && !phone) {
  //             return false;
  //           }
  //           return true;
  //         }
  //         return false;
  //       });
  //     }
  //     return [];
  //   },
  //   menuItems: [
  //     {
  //       name: i18n.t('common.invite'),
  //       icon: <SendIcon />,
  //       status: 'PREASSIGNED',
  //       handleClick: handleSendCode
  //     },
  //     {
  //       name: i18n.t('common.resend'),
  //       icon: <ResendIcon />,
  //       status: 'INVITATION_EXPIRED',
  //       handleClick: handleResendCode
  //     },
  //     {
  //       name: i18n.t('common.resend'),
  //       icon: <ResendIcon />,
  //       status: 'INVITATION_PENDING',
  //       handleClick: handleResendCode
  //     }
  //   ]
  // }
};
