/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import superagent from 'superagent';
import superagentPromise from 'superagent-promise';
import { includes } from 'lodash';

// Stores
import AuthStore from '@Stores/AuthStore';
import CommonStore from '@Stores/CommonStore';

// data providers imports
import AuthProviders from '@Providers/AuthProvider';
import UserProviders from '@Providers/UserProvider';
import RoleProviders from '@Providers/RoleProvider';
import GroupProviders from '@Providers/GroupProvider';
import InvitationProviders from '@Providers/InvitationProvider';
import DeckProviders from '@Providers/DeckProvider';
import DepositsProviders from '@Providers/DepositsProvider';
import PaymentsProviders from '@Providers/PaymentsProvider';
import NpsProviders from '@Providers/NpsProvider';
import WithdrawalProviders from '@Providers/WithdrawalProvider';
import StatisticProviders from '@Providers/StatisticProvider';
import CareProtocolProviders from '@Providers/CareProtocolProvider';
import ReportingProviders from '@Providers/ReportingProvider';
import CareRecoveryProviders from '@Providers/CareRecoveryProvider';
import WalletsProviders from '@Providers/WalletsProvider';

const API_BASE = CommonStore.AUTHORIZATION_API;
const BILLING_API_BASE = CommonStore.BILLING_API;
const NPS_API_BASE = CommonStore.NPS_API;
const BRIDGE_API_BASE = CommonStore.BRIDGE_API;
const CARE_PROTOCOL_API_BASE = CommonStore.CARE_PROTOCOL_API;
const REPORTING_API_BASE = CommonStore.REPORTING_API;
const CARE_RECOVERY_API_BASE = CommonStore.CARE_RECOVERY_API;
const WALLETS_API_BASE = CommonStore.WALLETS_API;

const PAGE = 'x-pagination-current-page';
const PAGE_COUNT = 'x-pagination-page-count';

const api = superagentPromise(superagent, global.Promise);

const responseBody = response => {
  const headers = response.header;
  const last = +headers[PAGE_COUNT] - +headers[PAGE] <= 1;

  if (Array.isArray(response.body)) {
    return {
      content: response.body,
      ...headers,
      last
    };
  }

  if (response.xhr.responseType === 'blob') {
    return response.body;
  }

  return {
    ...headers,
    last,
    ...response.body
  };
};

const handleErrors = (err, shouldPreventErrorAction = false) => {
  /*
    Return undefined just for validation of arrow function (it should always
    return a value)
  */
  if (!(err && err.response) || !AuthStore.authData.token) {
    return undefined;
  }
  const errorsForHandle = {
    auth: [401, 402],
    permissions: [403],
    notFound: [404]
  };
  const shouldHandleErrors = errors =>
    includes(errors, err.response.status) && !shouldPreventErrorAction;

  if (shouldHandleErrors(errorsForHandle.auth)) {
    AuthStore.logout();
    return err;
  }
  if (shouldHandleErrors(errorsForHandle.permissions)) {
    AuthStore.handleAccessPermissionsError();
    return err;
  }
  if (shouldHandleErrors(errorsForHandle.notFound)) {
    CommonStore.handleNotFoundError();
    return err;
  }
  return err;
};

const tokenPluginAuth = () =>
  Promise.resolve(req => {
    req.set('Authorization', 'Basic Y2FyZS1iYWNrZW5kOnNlY3JldA==');
    return req;
  });

const tokenMiddleware = req => {
  const {
    authData: { token }
  } = AuthStore;

  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }
  return req;
};

const tokenPlugin = () =>
  new Promise(resolve => {
    const {
      authData: { expiredDate }
    } = AuthStore;

    if (expiredDate < new Date().getTime()) {
      AuthStore.refreshToken().then(() => resolve(tokenMiddleware));
    } else {
      resolve(tokenMiddleware);
    }
  });

const getRequestProvider = (URL, plugin) => {
  return {
    get: (url, query) =>
      plugin().then(middleware =>
        api
          .get(`${URL}${url}`)
          .query(query)
          .use(middleware)
          .end(err => handleErrors(err, true))
          .then(responseBody)
      ),
    put: (url, body) =>
      plugin().then(middleware =>
        api
          .put(`${URL}${url}`, body)
          .use(middleware)
          .end(err => handleErrors(err))
          .then(responseBody)
      ),
    post: (url, body, query, isBlob) => {
      return plugin().then(middleware =>
        api
          .post(`${URL}${url.path || url}`, body)
          // TODO: temporary - waiting when back del query in post
          .query(query)
          .responseType(isBlob ? 'blob' : '')
          .use(middleware)
          .end(err => handleErrors(err))
          .then(responseBody)
      );
    },
    patch: (url, body) => {
      return plugin().then(middleware =>
        api
          .patch(`${URL}${url.path || url}`, body)
          .use(middleware)
          .end(err => handleErrors(err))
          .then(responseBody)
      );
    },
    del: url =>
      plugin().then(middleware =>
        api
          .del(`${URL}${url}`)
          .use(middleware)
          .end(err => handleErrors(err, true))
          .then(responseBody)
      )
  };
};

const requests = getRequestProvider(API_BASE, tokenPlugin);
const authRequests = getRequestProvider(NPS_API_BASE, tokenPluginAuth);
const billingRequests = getRequestProvider(BILLING_API_BASE, tokenPlugin);
const npsRequests = getRequestProvider(NPS_API_BASE, tokenPlugin);
const bridgeRequests = getRequestProvider(BRIDGE_API_BASE, tokenPlugin);
const careProtocolRequests = getRequestProvider(
  CARE_PROTOCOL_API_BASE,
  tokenPlugin
);
const reportingRequests = getRequestProvider(REPORTING_API_BASE, tokenPlugin);

const careRecoveryRequests = getRequestProvider(
  CARE_RECOVERY_API_BASE,
  tokenPlugin
);

const walletsRequests = getRequestProvider(WALLETS_API_BASE, tokenPlugin);

export const AuthProvider = AuthProviders(authRequests);

export const UserProvider = UserProviders(requests);

export const RoleProvider = RoleProviders(requests);

export const GroupProvider = GroupProviders(requests);

export const InvitationProvider = InvitationProviders(npsRequests);

export const DeckProvider = DeckProviders(requests);

export const DepositsProvider = DepositsProviders(billingRequests);

export const PaymentsProvider = PaymentsProviders(billingRequests);

export const NpsProvider = NpsProviders(npsRequests);

export const WithdrawalProvider = WithdrawalProviders(bridgeRequests);

export const StatisticProvider = StatisticProviders(npsRequests);

export const CareProtocolProvider = CareProtocolProviders(careProtocolRequests);

export const ReportingProvider = ReportingProviders(reportingRequests);

export const CareRecoveryProvider = CareRecoveryProviders(careRecoveryRequests);

export const WalletsProvider = WalletsProviders(walletsRequests);
