/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import { observable, action } from 'mobx';
import i18next from 'i18next';

// Providers
import { AuthProvider, NpsProvider, CareRecoveryProvider } from '@Providers';

// Stores
import CommonStore from '@Stores/CommonStore';
import GroupStore from '@Stores/GroupStore';
import ValidationStore from '@Stores/ValidationStore';
import RoleStore from '@Stores/RoleStore';
import NetworkStore from '@Stores/NetworkStore';
import InvitationStore from '@Stores/InvitationStore';
import MembersPaymentsStore from '@Stores/MembersPaymentsStore';
import ServicePaymentsStore from '@Stores/ServicePaymentsStore';
import DepositsStore from '@Stores/DepositsStore';

import { AuthForms as forms } from '@Assets/config/forms/auth';

// Configs
import { tableConfig } from '@Assets/config/tables/termsAndConditions';

// Utils
import { getParsedCookies, parseTokens } from '@Utils/formatting';
import isEmpty from 'lodash/isEmpty';

const MAX_COOKIES_SIZE = 4000;

export class AuthStore extends ValidationStore {
  @observable errors = null;

  @observable forms = forms;

  @observable resetPasswordStep = null;

  @observable isAccessPermissionsError = false;

  @observable authData = parseTokens();

  @observable careWalletId = null;

  @observable permissions = getParsedCookies().permissionNames;

  @observable verificationCode = '';

  @observable recoveryQuestions = [];

  @observable recoveryQuestionsIds = [];

  oldPassword = '';

  resetPasswordPhone = '';

  _checkingInterval = null;

  constructor() {
    super();
    const userData = JSON.parse(window.localStorage.getItem('currentUserInfo'));

    if (userData && userData.care_wallet_id) {
      this._setCareWalletId(userData.care_wallet_id);
    }

    this._checkSession();

    if (!isEmpty(this.authData)) {
      this._checkingInterval = setInterval(this._checkCookies, 1000);
    }
  }

  @action('AuthStore => resetForms') resetForms = () => {
    this.forms = forms;
  };

  @action('AuthStore => _setAuthData') _setAuthData = userData => {
    // TODO: check if all data/logic needed
    const roles = userData.roles;

    this.authData = {
      roles: JSON.stringify(roles),
      expiredPassword: userData.expiredPassword,
      token: userData.access_token,
      refreshToken: userData.refresh_token,
      expiredDate: new Date().getTime() + userData.expires_in * 1000,
      expiredTime: userData.expires_in * 1000
    };
  };

  @action('AuthStore => _updatePermissions')
  _updatePermissions = permissions => {
    ServicePaymentsStore.setPermissionNames(permissions);
    this.permissions = permissions;
    this.authData.permissionNames = JSON.stringify(permissions);
  };

  @action('AuthStore => _clearPermissions') _clearPermissions = () => {
    this.permissions = [];
  };

  @action('AuthStore => _deleteAuthData') _deleteAuthData = () => {
    this.authData = {};
  };

  _setCookie = ({ name, value = '' }) => {
    document.cookie = `${name}=${value}; path=/`;
  };

  _setCookies = () => {
    Object.keys(this.authData).forEach(name => {
      const value = `${this.authData[name]}`;

      if (value.length > MAX_COOKIES_SIZE) {
        const regExp = new RegExp(`.{1,${MAX_COOKIES_SIZE}}`, 'g');

        value.match(regExp).forEach((item, index) => {
          this._setCookie({ name: `${name}-${index}`, value: item });
        });
      } else {
        this._setCookie({ name, value });
      }
    });
  };

  _deleteCookies = () => {
    document.cookie.split(';').forEach(item => {
      const equalPosition = item.indexOf('=');
      const name = equalPosition > -1 ? item.substr(0, equalPosition) : item;

      this._setCookie({ name });
    });
  };

  _checkSession = () => {
    if (this.authData.expiredDate > new Date().getTime()) {
      return;
    }
    this.reset();
  };

  _checkCookies = () => {
    const currentCookies = parseTokens();

    if (isEmpty(currentCookies)) {
      this.logout();
      clearInterval(this._checkingInterval);
    }
  };

  @action('AuthStore => handle errors') _handleErrors = (err = {}) => {
    const resMessage =
      (err.response &&
        err.response.body &&
        err.response.body.error_description) ||
      i18next.t('auth.login.unhandledError');
    this.setErrorMessage(resMessage);
    throw err;
  };

  @action('AuthStore => login') login = () => {
    const { login, password } = this.forms.signInForm.fields;
    this.errors = null;

    CommonStore.setBtnDisabled();
    return AuthProvider.loginUser({
      username: `+${login.value}`,
      password: password.value
    })
      .then(userData => {
        this._setAuthData(userData);
        this._setCareWalletId(userData.careWalletId);

        if (userData.expiredPassword) {
          this.oldPassword = password.value;
          return userData;
        }

        return NpsProvider.getCurrentRolesWithPermissions();
      })
      .then(
        action(({ roles, ...data }) => {
          this._setCareWalletId(data.cw_id);
          if (!roles) {
            return data;
          }

          const permissions = roles
            .map(role => role.resources)
            .reduce((acc, val) => acc.concat(val), [])
            .map(permission => permission.code);
          this._updatePermissions([...new Set(permissions)]);
          tableConfig.updateMenuItems();
          this._setCookies();
          this._checkingInterval = setInterval(this._checkCookies, 1000);
          CommonStore.getUserRole();
          return permissions;
        })
      )
      .catch(this._handleErrors)
      .finally(CommonStore.setBtnEnabled);
  };

  @action('AuthStore => _setCareWalletId') _setCareWalletId = careWalletId => {
    this.careWalletId = careWalletId;
  };

  @action('AuthStore => _clearCareWalletId') _clearCareWalletId = () => {
    this.careWalletId = null;
  };

  @action('AuthStore => refreshToken') refreshToken = () => {
    const { refreshToken } = this.authData;

    return AuthProvider.refreshToken(refreshToken)
      .then(userData => {
        this._setAuthData(userData);
        this._setCookies();
      })
      .catch(this._handleErrors);
  };

  sendPhoneForResetPassword = () => {
    const {
      resetPasswordPhone: {
        fields: { phone }
      }
    } = this.forms;

    if (phone.value) {
      this.resetPasswordPhone = phone.value;
    }

    return AuthProvider.sendPhoneForReset(`+${this.resetPasswordPhone}`)
      .then(() => this.handleChangeForm(2))
      .catch(this._handleErrors);
  };

  @action('AuthStore => setVerificationCode') setVerificationCode = code => {
    this.verificationCode = code;
  };

  @action('AuthStore => clearVerificationCode') clearVerificationCode = () => {
    this.verificationCode = '';
  };

  @action('AuthStore => resetPassword') resetPassword = () => {
    const {
      resetPassword: {
        fields: { passwordConfirm }
      }
    } = this.forms;

    return AuthProvider.resetPassword({
      phone: `+${this.resetPasswordPhone}`,
      code: this.verificationCode,
      password: passwordConfirm.value
    }).catch(this._handleErrors);
  };

  @action('AuthStore => resetExpiredPassword') resetExpiredPassword = () => {
    return NpsProvider.updatePassword({
      old: this.oldPassword,
      password: this.forms.resetPassword.fields.password.value
    }).catch(this._handleErrors);
  };

  @action('AuthStore => setErrorMessage') setErrorMessage = errorText => {
    this.errors = errorText;
  };

  @action('AuthStore => handleChangeForm') handleChangeForm = step => {
    this.clearErrorMessage();
    this.resetPasswordStep = step;
  };

  @action('AuthStore => clearInputFields') clearInputFields = (
    form,
    fields
  ) => {
    fields.forEach(item => {
      this.forms[form].fields[item].value = '';
      this.forms[form].fields[item].error = '';
    });
  };

  @action('AuthStore => clearErrorMessage') clearErrorMessage = () => {
    this.errors = null;
  };

  @action('AuthStore => reset data in store') reset() {
    this.forms = forms;
    this.resetPasswordStep = null;
    this._deleteCookies();
    this._deleteAuthData();
    this._clearCareWalletId();
    this._clearPermissions();
    this._clearContainer();
    window.localStorage.removeItem('container');
  }

  @action('AuthStore => logout') logout = () => {
    this.reset();
    RoleStore.reset();
    CommonStore.reset();
    MembersPaymentsStore.reset();
    ServicePaymentsStore.reset();
    MembersPaymentsStore.reset();
    MembersPaymentsStore.clearDateRange();
    ServicePaymentsStore.clearDateRange();
    DepositsStore.clearDateRange();
    // TODO: Need to reset all stores on logout in one line of code
    NetworkStore.clearUsers();
    NetworkStore.resetPage();
    NetworkStore.clearQuery();
    InvitationStore.resetPage();
    InvitationStore.clearQuery();
    GroupStore.resetPage();
    GroupStore.clearQuery();
  };

  @action('AuthStore => handleAccessPermissionsError')
  handleAccessPermissionsError = () => {
    // Handle only for authenticated users
    if (this.authData.token) {
      this.isAccessPermissionsError = true;
    }
  };

  @action('AuthStore => skipAccessPermissionsError')
  skipAccessPermissionsError = () => {
    this.isAccessPermissionsError = false;
  };

  @action('AuthStore => getRecoveryQuestions')
  getRecoveryQuestions = () => {
    this.recoveryQuestions = [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      'Enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      'Veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
    ].map(item => {
      return { name: item, id: item };
    });
  };

  @action('AuthStore => markSelectedQuestion')
  markSelectedQuestion = (selectedValue, questionGroup) => {
    // TODO Need to refactoring!
    this.recoveryQuestions = this.recoveryQuestions.map(question => {
      if (questionGroup === question.questionGroup && question.selected) {
        return { ...question, selected: false };
      }
      return question;
    });
    this.recoveryQuestions = this.recoveryQuestions.map(question => {
      return selectedValue === question.id
        ? { ...question, selected: true, questionGroup }
        : question;
    });
  };

  @action('AuthStore => getContainer') getContainer = () => {
    CommonStore.setPending();
    return CareRecoveryProvider.getContainer().finally(
      CommonStore.clearPending
    );
  };

  @action('AuthStore => getRecoveryQuestions') getRecoveryQuestions = () => {
    return CareRecoveryProvider.getRecoveryQuestions().then(response => {
      const questions = response.content.length
        ? response.content
        : [
            {
              guid: 'd47ac10b-58cc-0372-8567-0e02b2c3d479',
              question: "Your dady's middle name"
            },
            {
              guid: 'f47ac10b-58cc-0372-8567-0e02b2c3d479',
              question: 'Your first phone number'
            },
            {
              guid: 'a47ac10b-58cc-0372-8567-0e02b2c3d479',
              question: 'Your day of birth'
            },
            {
              guid: 'c47ac10b-58cc-0372-8567-0e02b2c3d479',
              question: 'Your grand ma pet'
            },
            {
              guid: 'b47ac10b-58cc-0372-8567-0e02b2c3d479',
              question: 'Your favorite color'
            }
          ];
      const mappedQuestions = questions.map(question => {
        return { name: question.question, id: question.guid };
      });
      this.setRecoveryQuestions(mappedQuestions);
      this.setRecoveryQuestionsIds(questions.map(question => question.guid));
    });
  };

  @action('AuthStore => setRecoveryQuestions') setRecoveryQuestions = data => {
    this.recoveryQuestions = data;
  };

  @action('AuthStore => setRecoveryQuestionsIds')
  setRecoveryQuestionsIds = data => {
    this.recoveryQuestionsIds = data;
  };

  @action('AuthStore => createContainer') createContainer = bodyParams => {
    CommonStore.setPending();
    return CareRecoveryProvider.createContainer(bodyParams).finally(
      CommonStore.clearPending
    );
  };

  @action('AuthStore => _clearContainer')
  _clearContainer = () => {
    this.container = '';
  };
}

export default new AuthStore();
